<template>
  <div :class="['order-list-card mb-8', type]">
    <div class="order-list-item-header">
      <div class="mb-1">
        <span class="order-number">
          {{ order.ref_id || order.order }}
        </span>
        <span
          class="order-status"
          :class="order.payment_status || order.status"
        >
          {{ orderStatus }}
        </span>
      </div>
      <div v-if="order.is_deposit" class="text-sm">
        เลขที่ใบรับของ (บิลมัดจำ)
        <span class="font-bold">{{ depositNumber }}</span>
      </div>
      <div class="text-sm text-gray-medium-2">
        วันที่สั่งซื้อ {{ order.created_at | fullDateTimeTh }}
      </div>
    </div>
    <div class="order-list-item-body">
      <v-row>
        <v-col v-if="isShowShipping" cols="12" md="6">
          <v-row no-gutters>
            <v-col cols="12">
              <h5 class="mb-3">
                การจัดส่ง
              </h5>
            </v-col>
            <v-col cols="12" v-if="order.delivery_type == 'delivery'">
              <div class="d-flex flex-wrap">
                <div class="text-sm label-order-detail">
                  จัดส่งช่วงวันที่
                </div>
                <div>
                  {{ order.shipping_date }}
                </div>
              </div>
            </v-col>
            <v-col cols="12" v-if="order.delivery_type == 'pickup'">
              <div v-if="order.receive_branch_name" class="d-flex">
                <div class="text-sm label-order-detail">
                  สาขาที่เข้ารับ
                </div>
                <div>
                  {{ order.receive_branch_name }}
                </div>
              </div>
              <div v-if="order.shipping_date" class="d-flex">
                <div class="text-sm label-order-detail">
                  ช่วงวันที่เข้ารับ
                </div>
                <div>
                  {{ order.shipping_date }}
                  {{ order.receive_time }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          :md="isShowShipping ? 6 : 12"
          order-md="first"
        >
          <v-row no-gutters>
            <v-col cols="12">
              <h5 class="mb-3">
                รายการสั่งซื้อ
              </h5>
            </v-col>
            <v-col cols="12">
              <div class="product-items">
                <div class="product-item">
                  <div class="product-img">
                    <img :src="productImage" />
                  </div>
                  <div class="product-name">
                    <div>
                      {{
                        order.product_name || order.product_main.name
                      }}
                    </div>
                    <div class="text-sm text-gray-medium-2">
                      {{
                        order.product_sku || order.product_main.sku
                      }}
                    </div>
                  </div>
                </div>

                <template
                  v-if="
                    isShowBundleProducts && bundleProducts.length > 0
                  "
                >
                  <div
                    v-for="item in bundleProducts"
                    :key="item.id"
                    class="product-item"
                  >
                    <div class="product-img">
                      <img :src="item.detail.image_url" />
                    </div>
                    <div class="product-name">
                      <div>
                        {{ item.detail.name }}
                      </div>
                      <div class="text-sm text-gray-medium-2">
                        {{ item.detail.sku }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="order-list-item-footer">
      <v-row v-bind="{ justify: 'end' }">
        <v-col
          :cols="isShowViewDetail ? 6 : 12"
          sm="12"
          class="align-self-center"
        >
          <div class="text-sm text-gray-medium-2">
            ยอดรวม
          </div>
          <div class="text-lg font-bold">
            ฿ {{ order.total_price | currency2 }}
          </div>
        </v-col>
        <v-col
          v-if="isShowViewDetail"
          cols="6"
          sm="12"
          class="align-self-center"
        >
          <CustomButton
            color="gray"
            title="รายละเอียด"
            @click="openDetail"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { resizeImage } from '@/utils/helpers'
import CustomButton from '@/components/CustomButton'

const status = {
  create: {
    key: 'create',
    label: 'สร้าง',
  },
  process: {
    key: 'process',
    label: 'รอชำระเงิน',
  },
  complete: {
    key: 'complete',
    label: 'ชำระเงินสำเร็จ',
  },
  cancel: {
    key: 'cancel',
    label: 'ยกเลิก',
  },
}

export default {
  name: 'OrderListsPage',

  components: {
    CustomButton,
  },

  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'card',
    },
    isShowViewDetail: {
      type: Boolean,
      default: true,
    },
    isShowShipping: {
      type: Boolean,
      default: true,
    },
    isShowBundleProducts: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    orderStatus() {
      return (this.order.is_deposit ||
        this.order.payment_is_deposit) &&
        (this.order.payment_status === 'complete' ||
          this.order.status === 'complete')
        ? 'ชำระมัดจำสำเร็จ'
        : this.order.payment_status
        ? status[this.order.payment_status].label
        : this.order.status
        ? status[this.order.status].label
        : ''
    },

    depositNumber() {
      return this.order.deposit_number ?? 'กำลังดำเนินการ'
    },

    image() {
      return (
        this.order.main_image_url ||
        this.order.product?.image_url ||
        this.order.product_main?.image_url ||
        null
      )
    },

    productImage() {
      let image = this.order.product_main?.image_url
        ? resizeImage(this.order.product_main?.image_url, 300)
        : this.order.product?.image_url
        ? resizeImage(this.order.product?.image_url, 300)
        : this.order.main_image_url
        ? resizeImage(this.order.main_image_url, 300)
        : null

      return image
    },

    bundleProducts() {
      return (
        (this.order?.bundle_products &&
          this.order?.bundle_products?.filter(
            item => item.type !== 'delivery',
          )) ??
        []
      )
    },
  },

  data() {
    return {
      status,
    }
  },

  methods: {
    openDetail() {
      window.open(this.order.link, '_blank')
    },
  },
}
</script>

<style lang="stylus" scoped>
.order-list-card.card
    border: 1px solid $color-bnn-gray-light
    border-radius: 8px

.order-list-item-header
    padding: 12px
    border-bottom: 1px solid $color-bnn-gray-light

.order-list-item-body,
.order-list-item-footer
    padding: 0px 12px

.text-gray-medium-2
    color: $color-bnn-gray-medium-2

.product-items
	display: flex
	flex-direction: column
	gap: 24px

.product-item
    display: flex
    gap: 12px

.product-img {
    width: 48px
    height: 48px

    > img {
      width: 100%
      height: 100%
      object-fit: contain
    }
}

.product-name
  flex: auto

.order-number
    font-weight: bold
    text-transform: uppercase

.order-status
    padding: 2px 4px
    border-radius: 4px
    font-size: 12px
    margin-left: 4px
    background: $color-bnn-gray-medium-2
    color: white
    font-weight: bold

    &.complete
        background: $color-green

    &.process
        background: $color-warning

    &.create
        background: $color-bnn-gray-light-2
        color: $color-bnn-gray-medium

    &.cancel
        background: $color-bnn-red

.label-order-detail
	min-width: 110px
	max-width: 110px
	flex: auto
	color: $color-bnn-gray-medium-2
	&.flex-1
		flex: 1
</style>
